@font-face {
    font-family: Forza;
    src: url('./../assets/fonts/Forza-Medium.woff') format('woff'), url('./../assets/fonts/Forza-Medium.woff2') format('woff2');
  }
  
  @font-face {
    font-family: Tungsten;
    src: url('./../assets/fonts/Tungsten-Medium.woff') format('woff'), url('./../assets/fonts/Tungsten-Medium.woff2') format('woff2');
  }
  
  @font-face {
    font-family: Rubik;
    src: url('./../assets/fonts/Rubik-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: Saira;
    src: url('./../assets/fonts/saira-v14-latin-regular.woff2') format('woff2'), url('./../assets/fonts/saira-v14-latin-regular.woff') format('woff');
  }
  
  @font-face {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }