@use 'variables';

.hide-expander-header.k-expander {
    border: none;
    background-color: unset;
}

.hide-expander-header>.k-expander-header {
    display: none;
}

.hide-expander-header>.k-expander-content-wrapper>.k-expander-content {
    padding-block: unset;
    padding-inline: unset;
    padding: 2%;
}

.hide-expander-padding > .k-expander-content-wrapper > .k-expander-content {
  padding-block: unset;
  padding-inline: unset;
  padding: 0 !important;
}

.filtterddl > .k-picker-flat {
  border-bottom: 2px solid !important;
}

kendo-dateinput.startdatewidth.k-input.k-dateinput.k-input-md.k-rounded-md.k-input-solid {
  width: 67px;
}

.startdatewidth > input {
  padding-right: 0px !important;
}

.enddatewidth > input {
  padding-left: 0px !important;
}

.k-calendar .k-range-start, .k-calendar .k-range-end, .k-calendar .k-range-mid {
  background-color: color-mix(in srgb, #287DA1 25%, transparent) !important;
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected .k-link {
  background-color: #287DA1 !important;
}

.k-calendar .k-range-start .k-calendar-cell-inner, .k-calendar .k-range-start .k-link, .k-calendar .k-range-end .k-calendar-cell-inner, .k-calendar .k-range-end .k-link {
  background-color: #287DA1 !important;
}

.k-button-flat-primary {
  color: #287DA1 !important;
}

.k-calendar .k-calendar-view .k-today {
  color: #287DA1 !important;
}

.k-input-md.k-rounded-md.k-input-solid.k-input {
    border: unset;
    box-shadow: unset;
    border-bottom: 2px black solid;
    border-radius: 0;
    background-color: unset;
}

.k-textbox .k-input-inner {
    padding-left: 0;
}

.k-multiselect::after {
    display: block;
    content: '';
    background-image: url(./../assets/icons/caret-down-solid.svg);
    background-size: 12px 12px;
    height: 12px;
    width: 12px;
    margin-top: 5px;
    margin-right: 5px;
}


.k-picker-solid {
    background-color: white;
}

.k-list-item.k-disabled {
    background-color: white;
    color: variables.$muted-text;
    box-shadow: none;
}

.k-chip-outline-base {
    border: variables.$rail-performance-blue solid 2px;
    color: variables.$rail-performance-blue;
}

.k-chip-outline-base:hover {
    background-color: variables.$rail-performance-blue;
}

.k-chip-outline-base.k-selected {
    background-color: variables.$rail-performance-blue;
}

.k-chip-content {
    padding: 8px;
}

.k-chip-label {
    overflow: visible;
}

.k-grid {
    font-family: 'Open Sans';
    font-style: normal;
}

.k-grid,
.k-grid-header,
.k-table,
.k-table-th,
.k-table-td,
.k-grid td {
    border: none;
}

.k-grid-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row>.k-table-th,
.k-filter-row>td,
.k-filter-row>.k-table-td {
    border: none;
}

.k-table-th {
    border-bottom: solid 1px;
}

.k-grid .k-table-row.k-table-alt-row {
    background-color: unset;
}

.k-grid-toolbar {
    background-color: unset !important;
}

.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
    background-color: unset;
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
    background-color: unset;
}

.k-table-header {
    background-color: unset;
}

.k-grid .k-grid-header-wrap {
    border-bottom: 1px solid;
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-grid .k-grid-header .k-table-th {
    font-weight: bold;
}

.k-pager {
    background-color: unset;
}

.k-input-solid.k-multiselect.k-input {
    background-color: unset;
}


.k-window-content:first-child {
    padding: 0;
}

.k-window-content:last-child {
    padding: 0;
}

.k-window-content.k-dialog-content {
    padding: 0;
}

.k-label {
    margin-bottom: 2%;
}


.k-window-content:has(.dialog-padding) {
    overflow: hidden;
}


.edit-notification .k-notification-actions {
    display: none;
}


.k-tabstrip-item {
    border: none;
    width: 50%;
}

.k-tabstrip-content {
    border: none;
}

.k-tabstrip-content:focus {
    outline: none;
}

.k-tabstrip-items .k-item {
    border: none;
}

.k-tabstrip-items .k-link {
    justify-content: center;
    font-weight: bold;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-active {
    margin-bottom: unset;
}

.k-tabstrip-items .k-item {
    color: variables.$muted-text !important;
    font-size: 16px;
}

.k-tabstrip-items .k-item .k-link {
    border-bottom: 3px solid variables.$muted-text;
}

.k-tabstrip-items .k-item.k-active {
    color: variables.$rail-performance-blue !important;
    font-size: 16px;
}

.k-tabstrip-items .k-item.k-active .k-link {
    border-bottom: 3px solid variables.$rail-performance-blue;
}
