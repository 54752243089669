@use 'sass:color';
@use 'variables';
@use 'kendo';
@use 'fonts';

@import url('https://js.arcgis.com/4.30/@arcgis/core/assets/esri/themes/light/main.css');

fusioncharts {
  margin: 1px;
}

g[class^="raphael-group-"][class$="-messageGroup"],
g[class*=" raphael-group-"][class$="-messageGroup"] {
  display: none;
}

.muted {
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.16px !important;
  font-feature-settings: 'liga' off !important;
  color: variables.$muted-text !important;

  &-small {
    @extend .muted;
    font-size: small !important;
  }
}

.dropdown-item:active {
  background-color: #287DA1 !important;
}

.content-width {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 1440px;
  left: calc(50% - 1440px / 2);
}

.dropdown {
  margin-top: 1px;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.spinner-border {
  z-index: 1000;
}

.area-container {
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 1%;
}

.center-in-chart {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  /* line-height: 30px; */
  /* identical to box height, or 150% */
  letter-spacing: 1px;
  // text-transform: uppercase;
  /* Black */
  color: black;
}

.divider {
  border-top: 1px solid lightgrey;
  margin-top: 15px;
  margin-bottom: 15px;
}

.report-filters {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 22px;
}

.report-filter {
  width: 25%;
  box-sizing: border-box;
}

.report-select {
  appearance: none;
  widtH: 100%;
  background-color: transparent;
  background-origin: content-box;
  background-image: url('/assets/icons_reports/custom_select_arrow.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
  box-sizing: border-box;
  border: 0px;
  border-bottom: 1px solid black;
}

.heading {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
}

.report-header-action {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-decoration: none;
  text-transform: uppercase;
  color: variables.$rail-performance-blue !important;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.filter-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2px;
  /* Primary Grey */
  color: variables.$muted-text;
}

.report-filters-actions {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  gap: 22px;
}

.btn-action-primary {
  padding: 6px 30px;
  height: 48px;
  background: variables.$rail-performance-blue;
  font-family: 'Saira';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: white !important;
  border-radius: 0;

  &-small {
    @extend .btn-action-primary;
    font-size: small;
    height: 30px;
    padding: 2px 10px;
    line-height: 27px;
  }
}

.btn-action-primary:hover {
  background-color: color.scale(variables.$rail-performance-blue, $lightness: -20%) !important;
}

.btn-action-primary:disabled {
  background-color: color.scale(variables.$rail-performance-blue, $lightness: -20%) !important;
}

.btn-action-primary:active {
  background-color: variables.$rail-performance-blue;
}

.btn-action-secondary {
  padding: 6px 30px;
  gap: 16px;
  height: 48px;
  background: white;
  border: 1px solid variables.$rail-performance-blue;
  font-family: 'Saira';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: variables.$rail-performance-blue !important;
  border-radius: 0;

  &-small {
    @extend .btn-action-secondary;
    font-size: small;
    height: 30px;
    padding: 2px 10px;
    line-height: 27px;
  }
}

.btn-action-secondary:hover {
  background-color: color.scale(white, $lightness: -20%) !important;
}

.btn-action-secondary:disabled {
  background-color: color.scale(white, $lightness: -20%) !important;
}

.btn-action-secondary:active {
  background-color: white;
}

.btn-above-overlay {
  z-index: 1001;
}

.action-group {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  gap: 22px;
}

.dialog-padding {
  margin: 5%;
}

.grid-header {
  background-color: #ebebeb;
  border: #ebebeb;
}

.grid-cell {
  border: 1px solid #ebebeb !important;
}

.ellipsis-button {
  display: flex;
  background-color: unset;
  border: 0;
  background-image: url(./../assets/icons/ellipsis-vertical-solid.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.popup-button {
  background-color: white;
  font-family: 'Saira';
  font-style: normal;
  font-size: 16px;
  border: 0;
  padding: 5px 10px 5px 10px;
  text-align: left;
}

.popup-button img {
  width: 12px;
  height: 12px;
}


.dashboard-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* line-height: 30px; */
  /* identical to box height, or 150% */
  letter-spacing: 1px;
  text-transform: uppercase;
  /* Black */
  color: black;
}

.dashboard {
  /* Dataverse Module */
  top: 0px;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
}

.dashboard-divider {
  height: 10px;
}

.dashboard-subtitle {
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid lightgrey;
  padding-top: 10px;
  padding-bottom: 10px;
  /* P1 */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.16px;
  font-feature-settings: 'liga' off;
  /* Primary Grey */
  color: variables.$muted-text;
}

.dashboard-cards {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 22px;
}

.dashboard-card {
  width: 33%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 13px;
}

.performance-metric {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.performance-value {
  /* XXXXXX */
  font-family: 'Saira';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */
  letter-spacing: 1px;
  text-transform: uppercase;
  /* Black */
  color: black;
}

.performance-uom {
  /* Railcars */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.16px;
  font-feature-settings: 'liga' off;
  /* Primary Grey */
  color: variables.$muted-text;
  margin-top: 3px;
}

.performance-compare {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  /* 2% since last month */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.2px;
  /* Primary Grey */
  color: variables.$muted-text;
}

.performance-cards {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 22px;
}

.performance-card {
  width: 25%;
  box-sizing: border-box;
  /*    justify-content: center;
      align-items: center;*/
  padding: 38px 57px;
  gap: 13px;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px 20px;
  min-height: 150px;
  /* Utility Grey 2 */
  background: #F8F8F8;
  border-radius: 10px;
  /* Inside auto layout */
}

.performance-title {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  /* Medium Blue */
  color: variables.$rail-performance-blue;
  ;
  /* Inside auto layout */
}

.performance-title svg {
  vertical-align: middle;
}

.icon {
  color: variables.$rail-performance-blue !important;
  margin-right: 5px;
  width: 18px;
  height: auto;
  vertical-align: middle;
}

.text-primary {
  color: #287DA0 !important;
}

.btn-primary {
  color: #fff;
  background-color: #287DA0;
}

.validation-message-notification {
  font-size: 16px;
}

.query-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
